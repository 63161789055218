import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding-bottom: 0;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}
  @media(max-width: 600px) {
	height: auto;
	padding-bottom: 50px;
  }
`;

const FullHeightWrapper = ({ children }: { children: React.ReactNode }) => <StyledWrapper>{children}</StyledWrapper>;

export default FullHeightWrapper;
