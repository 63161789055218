import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Xicon from '../../images/closeIcon.svg';
import LogoSvg from '../../images/logo.svg';
import { ITemplateGraphqlData } from '../../templates/@types/ITemplateGraphqlData.interface';

const RegulationsPopupWrapper = styled.div`
 	background-color: white;
  	height: auto;
  	left: 0;
	padding: 40px 24px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;
  	@media(min-width: 1000px) {
	  padding: 50px 90px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
	  width: 1000px;
	}
`;
const ClosePopupButton = styled.img`
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 55px;
  top: 50px;
  width: 20px;
  @media(max-width: 600px) {
    height: 15px;
    width: 15px;
    top: 45px;
    right: 20px;
  }
`;
const Logo = styled.img`
	margin-bottom: 40px;
`;
const RegulationsTitle = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 16px;
	font-weight: 800;
	margin-bottom: 40px;
`;
const RegulationsContent = styled.div`
  padding-bottom: 60px;
  & > p {
    text-align: center;
    font-size: 0.813rem;
  }
  & > table  {
    font-size: 0.75rem;
  }
  & > ul {
    font-size: 0.75rem;
  }
  li {
    font-size: 0.75rem;
  }
  a {
    color: #2BF5BA;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
  }
`;

interface RegulationsPopupProps extends ITemplateGraphqlData {
	close: () => void;
	className: string;
}

const RegulationsPopup: FunctionComponent<RegulationsPopupProps> = ({ className, close, data }) => {
	const { markdownRemark } = data;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { frontmatter, html } = markdownRemark;
	return (
		<RegulationsPopupWrapper className={className}>
			<ClosePopupButton
				onClick={close}
				src={Xicon}
			/>
			<Logo src={LogoSvg} />
			<RegulationsTitle>Regulamin</RegulationsTitle>
			<RegulationsContent
				className="markdown-page-content"
				dangerouslySetInnerHTML={{ __html: html }}
			/>
		</RegulationsPopupWrapper>

	);
};
export default RegulationsPopup;
