import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import BasicButton from '../common/basic-button/BasicButton';
import KeySVG from '../../images/authorize/key.svg';
import BasicCheckbox from '../common/basic-checkbox/BasicCheckbox';
import { ITemplateGraphqlData } from '../../templates/@types/ITemplateGraphqlData.interface';
import { VotersAccessTypeEnum } from '../../_enums/votersAccessTypeEnum';
import { GlobalState } from '../../state/@types/redux.interface';
import { AuthorizeForm } from './@types/authorize.interface';
import RegulationsPopup from './RegulationsPopup';

const StyledLoginMaxWidthWrapper = styled.div`
    margin: 0 auto;
    max-width: 400px;
    overflow: auto;
`;

const StyledAuthorizeHeader = styled.h3`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1.49rem;
	font-weight: 800;
	margin-bottom: 0.71rem;
`;

const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	line-height: 1.42rem;
`;

const StyledLabel = styled.label`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

const StyledLabelText = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 0.35rem;
	text-transform: lowercase;
	&::before {
		background-color: ${({ theme }) => theme.colors.aqua};
		border-radius: 100%;
		content: '';
		display: block;
		height: 4px;
		margin-right: 0.5rem;
		width: 4px;
	}
`;

const StyledErrorText = styled(StyledLabelText)`
	color: ${({ theme }) => theme.colors.errorOrange};
	margin-bottom: 0;
	margin-top: 0.35rem;
	visibility: hidden;
	&.error {
		margin-bottom: 1.875rem;
		visibility: visible;
	}
	&::before {
		background-color: ${({ theme }) => theme.colors.errorOrange};
	}
`;

const StyledInput = styled.input`
	-webkit-appearance: none;
	appearance: none;
	background: ${({ theme }) => theme.colors.dirtyAqua};
	border: 3px solid ${({ theme }) => theme.colors.aqua};
	border-radius: 18px;
	box-shadow: none;
	color: ${({ theme }) => theme.colors.lightBlue};
	font-size: 12px;
	font-weight: 700;
	height: 48px;
	margin: 0;
	margin-top: 15px;
  	min-width: 280px;
	outline: none;
	padding: 0 1.5rem;
	vertical-align: middle;
	&.error {
		background-color: ${({ theme }) => theme.colors.lightErrorOrange};
		border: 2px solid ${({ theme }) => theme.colors.errorOrange};
	}
	@media(min-width: 900px) {
		min-width: 350px;
	}
`;

const StyledButtonWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	left: 0;
	margin-bottom: 50px;
	width: 100%;
`;

const RegulationCheckboxWrapper = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 12px;
	justify-content: center;
	margin-bottom: 40px;
`;
const OpenRegulations = styled.div`
	cursor: pointer;
	text-decoration: underline;
`;
const StyledRegulationsPopup = styled(RegulationsPopup)`
  color: ${({ theme }) => theme.colors.lightBlue};
  font-size: 12px;
  height: 100%;
  & > p {
    text-align: center;
  }
  & > table  {
    font-size: 0.813rem;
  }
`;
interface CodeFormProps extends AuthorizeForm, ITemplateGraphqlData {
	onSubmit: (code: string) => void;
	pushInputToTop: () => void;
	isPasswordForgotten: boolean;
	responseError: number;
}

const CodeForm: FunctionComponent<CodeFormProps> = ({
	pushInputToTop, onSubmit, authorizeError, data, isPasswordForgotten, responseError,
}) => {
	const [code, setCode] = useState('');
	const [dirty, setDirty] = useState(false);
	const authorizeType = useSelector((state: GlobalState) => state.event.data);
	const [areRegulationsAccepted, setAreRegulationsAccepted] = useState<boolean>(false);
	const [isRegulationsPopupOpen, setIsRegulationsPopupOpen] = useState<boolean>(false);
	const invalid = code.length < 8;
	const errorVisible = (invalid && dirty) || authorizeError;

	const errorMessage = () => {
		if (responseError === 410) {
			return 'Twój kod wygasł - Nowy kod został wysłany na maila';
		}
		return 'Błędny kod';
	};
	const onClick = () => {
		if (invalid) {
			setDirty(true);
		} else {
			onSubmit(code);
		}
	};
	const handleSendOnEnter = (e) => {
		if (e.keyCode === 13) {
			if (invalid) {
				setDirty(true);
			} else {
				// eslint-disable-next-line no-unused-expressions
				areRegulationsAccepted && onSubmit(code);
			}
		}
	};
	const getTextByAuthorizeType = (status: VotersAccessTypeEnum) => {
		if (status === VotersAccessTypeEnum.MAIL) {
			return (
				<StyledText>
					Wpisz kod
					Aby dołączyć do głosowania
					{' '}
					<strong>zaloguj się za pomocą kodu</strong>
					, który otrzymasz na mejla w przeciągu 3 minut.
					{' '}
					{' '}
					<strong>Nie zamykaj tego okna.</strong>
				</StyledText>
			);
		}
		if (status === VotersAccessTypeEnum.SMS) {
			return (
				<StyledText>
					Wpisz kod
					Aby dołączyć do głosowania
					{' '}
					<strong>zaloguj się za pomocą kodu</strong>
					, który otrzymasz w
					{' '}
					<strong>wiadomości SMS</strong>
					{' '}
					na podany przez Ciebie numer w przeciągu kilku minut.
					{' '}
					{' '}
					<strong>Nie zamykaj tego okna.</strong>
				</StyledText>
			);
		}
	};

	return (
		<>
			{isRegulationsPopupOpen && (
				<StyledRegulationsPopup
					close={() => setIsRegulationsPopupOpen(false)}
					data={data}
				/>
			)}
			<StyledLoginMaxWidthWrapper>
				<StyledAuthorizeHeader>
					<img
						alt="Key"
						src={KeySVG}
						style={{ marginRight: '10px' }}
					/>
					Wpisz kod
				</StyledAuthorizeHeader>
				{isPasswordForgotten ? (
					<StyledText>
						Nie pamiętasz hasła ?
						Nic nie szkodzi, wpisz kod
						{' '}
						<strong>otrzymany w wiadomości</strong>
						{' '}
						proces może zająć kilka minut
						{' '}
						<strong>Nie zamykaj tego okna.</strong>
					</StyledText>
				) : (
					getTextByAuthorizeType(authorizeType?.votersAccess)
				)}
				<StyledLabel>
					<StyledLabelText>8 cyfrowy kod dostępu</StyledLabelText>
					<StyledInput
						className={errorVisible ? 'error' : ''}
						maxLength={8}
						onChange={(event) => setCode(event.target.value.trim().toUpperCase())}
						onClick={pushInputToTop}
						onKeyDown={handleSendOnEnter}
						type="text"
						value={code}
					/>
					<StyledErrorText className={errorVisible ? 'error' : ''}>{errorMessage()}</StyledErrorText>
				</StyledLabel>
				<StyledButtonWrapper>
					{!isPasswordForgotten && (
						<RegulationCheckboxWrapper>
							<BasicCheckbox
								isChecked={areRegulationsAccepted}
								onClick={() => setAreRegulationsAccepted(!areRegulationsAccepted)}
							/>
							Akceptuję&nbsp;
							<OpenRegulations onClick={() => setIsRegulationsPopupOpen(true)}>
								regulamin
							</OpenRegulations>
						</RegulationCheckboxWrapper>
					)}

					<BasicButton
						onClick={areRegulationsAccepted || isPasswordForgotten ? onClick : null}
						style={{
							backgroundColor: areRegulationsAccepted || isPasswordForgotten ? '#2BF5BA' : '#EFEFF4',
							color: areRegulationsAccepted || isPasswordForgotten ? '#0C0B52' : '#C7C7D9',
						}}
					>
						dołącz do głosowania
					</BasicButton>
				</StyledButtonWrapper>
			</StyledLoginMaxWidthWrapper>
		</>
	);
};

export default CodeForm;
