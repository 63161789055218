import React, { useState } from 'react';
import styled from 'styled-components';
import BasicButton from '../common/basic-button/BasicButton';
import KeySVG from '../../images/authorize/key.svg';
import { AuthorizeForm } from './@types/authorize.interface';

const StyledLoginMaxWidthWrapper = styled.div`
	align-self: center;
	margin: 0 auto;
	max-width: 400px;
	overflow: auto;
`;

const StyledAuthorizeHeader = styled.h3`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1.49rem;
	font-weight: 800;
	margin-bottom: 0.71rem;
`;

const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	line-height: 1.42rem;
`;

const StyledLabel = styled.label`
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

const StyledLabelText = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 0.35rem;
	text-transform: lowercase;
	&::before {
		background-color: ${({ theme }) => theme.colors.aqua};
		border-radius: 100%;
		content: '';
		display: block;
		height: 4px;
		margin-right: 0.5rem;
		width: 4px;
	}
`;

const StyledErrorText = styled(StyledLabelText)`
	color: ${({ theme }) => theme.colors.errorOrange};
	margin-bottom: 0;
	margin-top: 0.35rem;
	visibility: hidden;
	&.error {
		margin-bottom: 1.875rem;
		visibility: visible;
	}
	&::before {
		background-color: ${({ theme }) => theme.colors.errorOrange};
	}
`;

const StyledInput = styled.input`
	-webkit-appearance: none;
	appearance: none;
	background: ${({ theme }) => theme.colors.dirtyAqua};
	border: 3px solid ${({ theme }) => theme.colors.aqua};
	border-radius: 18px;
	box-shadow: none;
	color: ${({ theme }) => theme.colors.lightBlue};
	font-size: 12px;
	font-weight: 700;
	height: 48px;
	margin: 0;
	margin-top: 15px;
	outline: none;
	padding: 0 1.5rem;
	vertical-align: middle;
	&.error {
		background-color: ${({ theme }) => theme.colors.lightErrorOrange};
		border: 2px solid ${({ theme }) => theme.colors.errorOrange};
	}
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	left: 0;
	margin-bottom: 30px;
	width: 100%;
`;
interface MailFormProps extends AuthorizeForm {
	onSubmit: (mail: string) => void;
	pushInputToTop: () => void;
	isPasswordForgotten: boolean;
}

const MailForm = ({
	pushInputToTop, onSubmit, authorizeError, isPasswordForgotten,
}: MailFormProps) => {
	const [email, setEmail] = useState('');
	const [dirty, setDirty] = useState(false);

	// eslint-disable-next-line max-len
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const invalid = !re.test(String(email).toLowerCase());
	const errorVisible = (invalid && dirty) || authorizeError;

	const onClick = () => {
		if (invalid) {
			setDirty(true);
		} else {
			onSubmit(email);
		}
	};
	const handleSendOnEnter = (e) => {
		if (e.keyCode === 13) {
			if (invalid) {
				setDirty(true);
			} else {
				onSubmit(email);
			}
		}
	};

	return (
		<StyledLoginMaxWidthWrapper>
			<StyledAuthorizeHeader>
				<img
					alt="Key"
					src={KeySVG}
					style={{ marginRight: '10px' }}
				/>
				Dołącz do głosowania
			</StyledAuthorizeHeader>
			{isPasswordForgotten ? (
				<StyledText>
					Nie pamiętasz hasła ?
					Nic nie szkodzi, wpisz kod
					{' '}
					<strong>otrzymany w wiadomości</strong>
					{' '}
					proces może zająć kilka minut
					{' '}
					<strong>Nie zamykaj tego okna.</strong>
				</StyledText>
			) : (
				<StyledText>Aby dołączyć do głosowania podaj adres email, na który otrzymasz 8 cyfrowy kod dostępu.</StyledText>
			)}
			<StyledLabel>
				<StyledLabelText>Adres email</StyledLabelText>
				<StyledInput
					className={errorVisible ? 'error' : ''}
					maxLength={250}
					onChange={(event) => setEmail(event.target.value.trim())}
					onClick={pushInputToTop}
					onKeyDown={handleSendOnEnter}
					type="text"
					value={email.toLowerCase()}
				/>
				<StyledErrorText className={errorVisible ? 'error' : ''}>Błędny adres email</StyledErrorText>
			</StyledLabel>
			<StyledButtonWrapper>
				<BasicButton onClick={onClick}>dołącz do głosowania</BasicButton>
			</StyledButtonWrapper>
		</StyledLoginMaxWidthWrapper>
	);
};

export default MailForm;
