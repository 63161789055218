import React from 'react';
import styled from 'styled-components';
import { BasicButtonProps } from '../@types/basicButton.interface';

const StyledButton = styled.button`
	align-items: center;
	background-color: ${({ theme, disabled }) => (disabled ? theme.colors.lightGreyBackground : theme.colors.aqua)};
	border: none;
	border-radius: 25px;
	box-shadow: none;
	color: ${({ theme }) => theme.colors.purple};;
	cursor: pointer;
	display: flex;
	font-family: 'Open Sans';
	font-size: 14px;
	font-weight: 700;
	justify-content: center;
	line-height: 14px;
	outline: none;
	padding: 14px 35px;
  	text-transform: lowercase;
`;

const BasicButton = ({
	children, onClick, style, className, disabled = false,
}: BasicButtonProps) => (
	<StyledButton
		className={className}
		disabled={disabled}
		onClick={disabled ? null : onClick}
		style={style}
	>
		{children}
	</StyledButton>
);

export default BasicButton;
