import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import Cookies from 'js-cookie';
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/common/footer/Footer';
import CompanyHeader from '../components/common/company-header/CompanyHeader';
import FullHeightWrapper from '../components/common/full-height-wrapper/FullHeightWrapper';
import {
	codeAuthorizeToEventAction, loadingPassword, loginAuthorizeToEventAction, setLoginAction,
} from '../state/authorize.reducer';
import CodeForm from '../components/authorize/CodeForm';
import SmsForm from '../components/authorize/SmsForm';
import MailForm from '../components/authorize/MailForm';
import { GlobalState } from '../state/@types/redux.interface';
import { resetCodeVerify } from '../services/services';
import { fetchEventByHashAction, setEventLoadingStatusAction } from '../state/event.reducer';
import { getLocalStorageItem, setLocalStorageItem } from '../_utils/localStorage';
import logoSEO from '../images/logoPNG.png';
import { VotersAccessTypeEnum } from '../_enums/votersAccessTypeEnum';

const StyledLoginWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	border-bottom-left-radius: 35px; 
	border-bottom-right-radius: 35px;
	color: #fff;
  	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 35px 20px;
	::-webkit-scrollbar {
		display: none;
	}
`;
const AuthorizeWrapper = styled.div`
  @media(max-width: 600px) {
    position: absolute;
    top: 0;
	width: 100%;
    &.goTop {
      transition: all .3s;
      top: -170px;
    }
  }
`;

const AuthorizePage = ({ location, data }) => {
	const eventHash = getLocalStorageItem('eventHash');
	const state = location?.state;
	const isPasswordForgotten = state?.isPasswordForgotten || false;

	const dispatch = useDispatch();
	const authorizeReducer = useSelector((state: GlobalState) => state.authorize);
	const { data: eventData, loading: eventLoading } = useSelector((state: GlobalState) => state.event);
	const [formState, setFormState] = useState(null);
	const [isTop, setTop] = useState(false);
	const [isCodeError, setIsCodeError] = useState<boolean>(false);

	const {
		error: authorizeError, loginLoading, isLoginAuth, isPasswordSet, isCodeAuth, responseError,
	} = authorizeReducer;

	useEffect(() => {
		if (eventHash) {
			dispatch(fetchEventByHashAction(eventHash));
		}
	}, [eventHash]);

	useEffect(() => {
		if (Cookies.get('isCode') || Cookies.get('isPasswordForgotten')) {
			setFormState('codes');
		}
		if ((eventData && !Cookies.get('isCode')) && !Cookies.get('isPasswordForgotten')) {
			setFormState(eventData.votersAccess);
		}
	}, [eventData]);

	const navigateToLogin = async () => {
		dispatch(setEventLoadingStatusAction(true));
		navigate('/login');
	};

	const navigateToRegister = () => {
		dispatch(setEventLoadingStatusAction(true));
		navigate('/register');
	};

	useEffect(() => {
		if (isLoginAuth) {
			if (isPasswordForgotten) {
				Cookies.set('isPasswordForgotten', true, { expires: moment().add(5, 'm').toDate() });
			}
			if (isPasswordSet && !Cookies.get('isPasswordForgotten')) {
				navigateToLogin();
			} else {
				if (!Cookies.get('isPasswordForgotten')) {
					Cookies.set('isCode', true, { expires: moment().add(5, 'm').toDate() });
				}
				setFormState('codes');
			}
		}
	}, [isLoginAuth]);

	useEffect(() => {
		if (isCodeAuth && eventData.votersAccess !== VotersAccessTypeEnum.CODES) {
			navigateToRegister();
		}
	}, [isCodeAuth]);

	useEffect(() => {
		if ((!eventData && loginLoading) || eventLoading) return;
		if (isPasswordSet && !Cookies.get('isPasswordForgotten')) {
			navigateToLogin();
		}
		if (isCodeAuth) {
			if (eventData.votersAccess === VotersAccessTypeEnum.CODES) {
				navigate('/room');
			} else {
				navigateToRegister();
			}
		}
		if (isPasswordSet === false) {
			setFormState('codes');
		}
	}, [authorizeReducer]);

	const onCodeSubmit = (code) => {
		setTop(false);
		setLocalStorageItem('code', code);
		dispatch(codeAuthorizeToEventAction(eventData.id, code));
	};
	const onCodeSubmitWhenResettingPassword = async (code) => {
		try {
			dispatch(loadingPassword(true));
			await resetCodeVerify(eventHash, getLocalStorageItem('login'), code);
			dispatch(loadingPassword(false));
			setIsCodeError(false);
			setTop(false);
			navigate('/forgottenPassword', {
				state: {
					code,
					eventHash,
				},
			});
		} catch (e) {
			if (e.status === 401) {
				setIsCodeError(true);
			}
		}
	};

	const onPhoneNumberSubmit = (phonePrefix, phoneNumber) => {
		setTop(false);
		dispatch(setLoginAction(phoneNumber));
		setLocalStorageItem('login', phoneNumber);
		dispatch(
			loginAuthorizeToEventAction(eventData.id, {
				phonePrefix,
				phoneNumber,
			}),
		);
	};

	const onEmailSubmit = (email) => {
		setTop(false);
		dispatch(setLoginAction(email));
		setLocalStorageItem('login', email);
		dispatch(loginAuthorizeToEventAction(eventData.id, { email }));
	};

	const pushInputToTop = () => {
		if (!isIOS) {
			setTop(true);
		}
	};

	return (
		<Layout>
			<AuthorizeWrapper className={isTop ? 'goTop' : ''}>
				<SEO
					description="Wyborek | Głosuj szybko i łatwo!"
					image={logoSEO}
					title="Zaloguj się"
				/>
				<CompanyHeader />
				<FullHeightWrapper>
					<StyledLoginWrapper>
						{(formState === 'codes' || (formState === 'codes' && Cookies.get('isPasswordForgotten'))) && (
							<CodeForm
								authorizeError={authorizeError || isCodeError}
								data={data}
								isPasswordForgotten={Cookies.get('isPasswordForgotten')}
								onSubmit={(Cookies.get('isPasswordForgotten') ? onCodeSubmitWhenResettingPassword : onCodeSubmit)}
								pushInputToTop={pushInputToTop}
								responseError={responseError?.statusCode}
							/>
						)}
						{formState === 'sms' && (
							<SmsForm
								authorizeError={authorizeError}
								isPasswordForgotten={Cookies.get('isPasswordForgotten')}
								onSubmit={onPhoneNumberSubmit}
								pushInputToTop={pushInputToTop}
							/>
						)}
						{formState === 'mail' && (
							<MailForm
								authorizeError={authorizeError}
								isPasswordForgotten={Cookies.get('isPasswordForgotten')}
								onSubmit={onEmailSubmit}
								pushInputToTop={pushInputToTop}
							/>
						)}
					</StyledLoginWrapper>
				</FullHeightWrapper>
				<Footer
					withRegulations
				/>
			</AuthorizeWrapper>
		</Layout>
	);
};

export default AuthorizePage;

export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { slug: { eq: "terms" } }) {
            html
            frontmatter {
                slug
                title
                subtitle
            }
        }
    }
`;
