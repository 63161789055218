import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Baner from '../../../images/authorize/dashedBaner.svg';
import Logo from '../../../images/logo.svg';
import { GlobalState } from '../../../state/@types/redux.interface';

const StyledHeaderSpace = styled.div`
	display: flex;
	height: 50vh;
	justify-content: center;
	min-height: 55px;
  	@media(max-width: 600px) {
	  height: 280px;
	}
	@media(min-width: 1000px) {
	  height: 40vh;
	}
 `;
const StyledBaner = styled.div`
	background-image: url(${({ Baner }) => Baner});
	background-position: top center;
	background-repeat: no-repeat;
	top: 20px;
	width: 100%;
`;
const StyledHeaderLogo = styled.img`
	align-self: center;
	height: 33px;
	position: absolute;
	top: 60px;
	width: 154px;
`;

const CompanyHeader = () => {
	const logoUrl = useSelector((state: GlobalState) => state.event.details);

	return (
		<StyledHeaderSpace>
			<StyledBaner Baner={Baner} />
			<StyledHeaderLogo src={logoUrl || Logo} />
		</StyledHeaderSpace>
	);
};

export default CompanyHeader;
