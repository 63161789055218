import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BasicCheckboxInterface } from '../@types/basicCheckbox.interface';

const Wrapper = styled.div`
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 5px;
	cursor: pointer;
	height: 19px;
	margin-right: 10px;
	min-width: 19px;
	padding: 1px;
`;

const Checked = styled.div`
	background-color: ${({ theme }) => theme.colors.aqua};
	border-radius: 3px;
	height: 100%;
	width: 100%;
`;

const BasicCheckbox: FunctionComponent<BasicCheckboxInterface> = ({ isChecked, onClick }) => (
	<Wrapper onClick={onClick}>
		{isChecked && (
			<Checked />
		)}
	</Wrapper>
);

export default BasicCheckbox;
