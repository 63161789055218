import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import LogoSvg from '../../../images/logo.svg';
import { FooterProps } from '../@types/footer.interface';

const StyledFooterWrapper = styled.div`
	align-items: center;
	background-color: #fff;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	left: 0;
	padding: 20px 32px;
	position: fixed;
	width: 100%;
  	z-index: 999;
`;
const StyledLogo = styled.img`
	height: 12px;
	@media (min-width: 600px) {
		height: 22px;
	} 
`;
const StyledRegulations = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 10px;
	font-weight: 600;
	margin-top: 0;
	a {
		color: ${({ theme }) => theme.colors.fontBlue};
		text-decoration: none;
		text-transform: lowercase;
	}
`;

const Footer: FunctionComponent<FooterProps> = ({ withRegulations }) => (
	<>
		<StyledFooterWrapper>
			<StyledLogo src={LogoSvg} />
			{withRegulations && (
				<StyledRegulations>
					<a
						href="https://wyborek.pl/regulamin"
						rel="noreferrer"
						target="_blank"
					>
						Regulamin
					</a>
					<span>&nbsp;i&nbsp;</span>
					<a
						href="https://wyborek.pl/regulamin"
						rel="noreferrer"
						target="_blank"
					>
						Polityka prywatności
					</a>
				</StyledRegulations>
			)}
		</StyledFooterWrapper>
	</>
);

export default Footer;
